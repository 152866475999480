<template>
  <div class="seven-year">
    <section class="center" v-if="step === 0">
      加载中...
    </section>

    <section class="center" v-if="step === -2">
      <p>
        <img src="https://static.weixinshu.com/assets/images/deer/cry.png"
             alt=""
             style="max-width: 200px;">
      </p>
      <h3>很抱歉，您不能参与本次活动</h3>
      <p>仅限普通用户参与哦</p>
    </section>

    <section class="center" v-if="step === -1">
      <p>
        <img src="https://static.weixinshu.com/assets/images/deer/cry.png"
             alt=""
             style="max-width: 240px;">
      </p>
      <h3>很抱歉，您还没有下过订单哦</h3>
      <p>关注公众号，下单后即可马上领取免费杂志册哦</p>
      <img src="@/assets/images/wechat-qrcode.jpg" alt="" style="max-width: 160px;">
    </section>

    <section v-if="step === 1">
      <div style="opacity: .6;">
        <svg style="opacity: 0; position: fixed; left: -1000px; top: -1000px;">
          <defs>
            <filter id="turnIntoBlue">
              <feFlood flood-color="#0660fc" flood-opacity="1" result="color" />
              <feComposite in="color" in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <img src="https://img.xinshu.me/upload/31ace076edaa47dbae04d61088d9de9e"
          alt="" style="filter: url('#turnIntoBlue')">
      </div>

      <div class="intro" v-if="started">
        <div class="intro-bg animation-fade-in-down"><img src="https://img.xinshu.me/upload/31f0246293474158907ff44e01cfea25" alt=""></div>
        <div class="intro-text">
          <div class="animation-fade-in-down" style="animation-delay: 2s">在过去的 <b class="highlight">7</b> 年里</div>
          <div class="animation-fade-in-down" style="animation-delay: 3s">我们共服务了 <b class="highlight">570.5</b> 万人</div>
          <div class="animation-fade-in-down" style="animation-delay: 4s">共出版 <b class="highlight">71.9</b> 万本纸质书</div>
          <div class="animation-fade-in-down" style="animation-delay: 5s">而你，出过几本书呢</div>
          <div class="animation-fade-in-down" style="animation-delay: 6s">正在统计中...</div>
        </div>
      </div>

      <p class="decoration">
        <img src="https://img.xinshu.me/upload/d2f3a84f43094f43a197d8e640c032df" alt="">
      </p>
    </section>

    <transition name="fade">
      <section v-if="step > 1">
        <div class="box">
          <header>
            <avatar :src="user.avatar" size="4em"/>
          </header>

          <div class="body">
            <div class="user-info">
              <div style="text-align: center;">
                <b>{{user.nickname}}</b>
                <div class="animation-fade-in-down" style="margin: 0.5rem 0;">您已出版<span class="highlight">{{total}}本</span>时光书，获得称号</div>
                <div class="title animation-fade-in-down">
                  <img src="https://img.xinshu.me/upload/a326a4a28d034068a409a86d2be3715a" alt="">
                  <div class="title-text">{{title}}</div>
                </div>
              </div>
            </div>
            <div class="books">
              <b-row align-h="center">
                <template v-if="orders.length > 0">
                  <b-col :cols="cols"
                        v-for="(order, index) in orders"
                        :key="order.id"
                        class="animation-fade-in-down"
                        :style="{'animation-delay': index * 300 + 'ms'}">
                    <div class="book">
                      <cover fill :book="order.book"/>
                      <div class="mt-2">
                        <div class="publish-year">{{order.createTime | moment('YYYY')}}年出版</div>
                      </div>
                    </div>
                  </b-col>
                </template>
                <template v-else>
                  <b-col cols="8" class="animation-fade-in-down">
                    <div class="book">
                      <img style="box-shadow: 0 0 10px rgba(0,0,0,.15);" src="https://canvas.xinshu.me/generate/cover-12?pic=&title=%E6%88%91%E7%9A%84%E6%97%B6%E5%85%89%E4%B9%A6&author=&format=jpeg&size=480"/>
                      <div class="mt-2">
                        <div class="publish-year">您的2021时光书待出版...</div>
                      </div>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </div>
          </div>
        </div>

        <div class="buttons animation-fade-in-down" style="animation-delay: 4500ms">
          <b-row>
            <b-col>
              <div class="button" @click="popupActive = true">向好友炫耀</div>
            </b-col>
          </b-row>
        </div>
      </section>
    </transition>

    <popup v-if="popupActive"
           :src="poster"
           @close="popupActive = false"/>
  </div>
</template>

<script>
import { until } from '@/utils/wait-for'

export default {
  name: 'sevenYear',
  title: '七周年回顾',
  data() {
    return {
      step: 0,
      total: 0,
      album: 0,
      started: false,
      popupActive: false,
      orders: [],
      title: ''
    }
  },
  computed: {
    cols() {
      if (this.orders.length <= 1) {
        return 8
      }
      if (this.orders.length <= 2) {
        return 6
      }
      return 4
    },
    poster() {
      const len = this.orders.length
      const url = 'https://canvas.xinshu.me/generate/7-year.00' + len
      const obj = {}
      for (let i = 0; i < len; i++) {
        obj['year' + (i + 1)] = this.$day(this.orders[i].createTime).format('YYYY') + '年出版'
        obj['cover' + (i + 1)] = this.getCoverUrl(this.orders[i].book, 320)
      }
      return url + this.serialize({
        avatar: this.user.avatar,
        nickname: this.user.nickname,
        order: this.title,
        nub: this.total,
        ...obj
      })
    }
  },
  created() {
    this.step = 0
    if (this.user.isAgent) {
      this.step = -2
      return
    }

    this.step = 1
    this.init()

    setTimeout(() => {
      this.started = true
    }, 500)

    setTimeout(() => {
      until(() => 1).then(() => {
        setTimeout(() => {
          this.step = 2
        }, 1000)
      })
    }, 7500)
  },
  methods: {
    init() {
      return this.$req.get('/api/activity/wxs_seventh_anniversary/orders').then(result => {
        this.total = result.bookOrderCount
        this.album = result.albumOrderCount
        this.orders = result.orders
        this.handleTitle()
      })
    },
    handleTitle() {
      if (this.total >= 16) {
        const names = ['心书文艺创作达人', '心书创作奇才', '心书大咖作家', '才华横溢创作大师', '最佳写作人']
        const idx = Math.floor((Math.random() * names.length))
        this.title = names[idx]
      } else if (this.total >= 7 && this.total <= 15) {
        const names = ['平平无奇写书小天才', '最佳文学创作者', '浪漫记录走心作家']
        const idx = Math.floor((Math.random() * names.length))
        this.title = names[idx]
      } else if (this.total >= 2 && this.total <= 6) {
        this.title = '美好生活记录者'
      } else if (this.total === 1) {
        const ord = this.orders[0]
        const bookType = ord.bookType
        if (bookType === 'blogbook' || bookType === 'diarybook') {
          this.title = '最具潜力作家'
        } else if (bookType === 'star-wbbook') {
          this.title = '爱豆达人'
        } else if (bookType === 'qbbbook' || bookType === 'bbsbook') {
          this.title = '完美家长认证'
        } else if (bookType === 'wxbook' || bookType === 'wbbook' || bookType === 'shuoshuo_book') {
          const names = ['美好生活记录者', '生活描边艺术家']
          const idx = Math.floor((Math.random() * names.length))
          this.title = names[idx]
        }
      } else if (this.total === 0) {
        if (this.album === 0) {
          this.title = '静候佳作'
        } else {
          this.title = '最佳新人奖'
        }
      } else {
        this.title = '心书文艺创作达人'
      }
    }
  }
}
</script>

<style lang="scss">
body[data-page="sevenYear"] {
  background-color: rgba(236, 244, 235);

  main {
    padding-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
$red: #FC593D;
$blue: #0660fc;

.seven-year {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 560px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://img.xinshu.me/upload/72d421c114d247b18a0a3a4d00263ffd);
  color: $blue;
  text-align: center;
  padding: 0;
}

section {
  position: relative;
  height: 100%;
  padding: 1.5rem;

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.intro {
  opacity: .85;
  color: $blue;
  font-size: 1.2em;
  line-height: 1.8;
  // font-weight: 600;
  position: relative;
  z-index: 1;
  margin-top: 2rem;
  position: relative;

  .intro-text {
    position: absolute;
    width: 100%;
    top: 37%;
  }
  .highlight {
    color: $red;
    font-size: 1.3em;
  }
}

.decoration {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  max-width: 320px;
  margin: auto;
}

.box {
  background-color: #fff;
  box-shadow: 0 0 4px #fff;
  border-radius: 12px;
  position: relative;
  margin-top: 2.5rem;

  .avatar {
    border: 2px solid #fff;
  }

  .body {
    padding: 2rem 1.5rem;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: center;
    margin-bottom: 2rem;
    .highlight {
      color: $red;
    }
    .title {
      width: 90%;
      margin-left: 5%;
      position: relative;
      .title-text {
        position: absolute;
        width: 100%;
        color: #fff;
        font-size: 2.5vh;
        top: 15%;
        font-style: italic;
      }
    }
  }

  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -2em;
  }
}

.book {
  margin-top: .5em;
  margin-bottom: .5em;
}

.publish-year {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 100px;
  border: 1px solid currentColor;
  font-size: 12px;
  white-space: nowrap;
}

.buttons {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
}

.button {
  display: block;
  cursor: pointer;
  background-color: $blue;
  padding: .75em 0;
  border-radius: 4px;
  font-size: 1.2rem;
  color: #fff;
}
</style>
